@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

@keyframes scroll-desktop {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  animation: linear scroll 20s infinite alternate-reverse;
}

.animate-scroll-desktop {
  animation: 'scroll-desktop' 10s linear infinite alternate;
}